document.querySelectorAll('[data-tooltip-controller]').forEach(el => {
  el.querySelector('[data-tooltip-activator]').addEventListener('click', (e) => {
    e.stopPropagation();
    const tooltipContentEl = el.querySelector('[data-tooltip-content]');
    const bodyHandler = (event) => {
      if (event.target.closest('[data-tooltip-content]') !== tooltipContentEl) {
        tooltipContentEl.classList.remove('opened');
        document.body.removeEventListener('click', bodyHandler);
      }
    }

    if (tooltipContentEl.classList.contains('opened')) {
      tooltipContentEl.classList.remove('opened');
      document.body.removeEventListener('click', bodyHandler);
    } else {
      tooltipContentEl.classList.add('opened');
      document.body.addEventListener('click', bodyHandler);
    }
  })
});
